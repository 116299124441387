.iconActive {
  color: #4ade80;
  text-shadow: 0 0 15px #00f3ff 0 0 15px #00f3ff;

  box-shadow: -1px -1px 4px rgba(255, 255, 255, 0, 0.05),
    4px 4px 6px rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px rgba(255, 255, 255, 0, 0.05),
    inset 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.analyzeButton {
  position: relative;

  font-size: 15px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 4px;
  border-radius: 10px;
  overflow: hidden;
}

@keyframes animate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.text {
  color: #00f3ff;
}

ul {
  position: relative;
}

ul li {
  list-style: none;
}

ui li label {
  position: relative;
}

ul li label input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

ul li label .icon {
  position: relative;
  width: 30px;
  height: 30px;

  color: #555;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 4px;
  border-radius: 10px;
  overflow: hidden;
}

ul li label .icon:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 5px);
  height: calc(50% - 2px);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

ul li label input[type="checkbox"]:checked ~ .icon {
}

ul li label input[type="checkbox"]:checked ~ .icon .iconColor {
  color: #4ade80;

  text-shadow: 0 0 15px #00f3ff, 0 0 25px #00f3ff;
}

/* input[type="checkbox"] {
  display: none;
} */

.active {
  color: #6b7280;

  padding: 4px;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

input[type="checkbox"]:checked + .active {
  color: rgb(34 197 94);
}
