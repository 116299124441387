.app-composition-logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  pointer-events: all;
  width: auto;
  max-width: 40px;
  height: auto;
  object-fit: contain;
}
