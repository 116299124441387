.navigation {
  position: relative;
  /* height: 500px; */
  width: 260px;
  background-color: #e8eaed;
  box-shadow: 10px 0 0 transparent;
  z-index: 2;
  display: flex;
  border-left: 10px solid #e8eaed;
}

:global(.dark) .navigation {
  position: relative;
  /* height: 500px; */
  width: 260px;
  background-color: rgb(27 28 34 / var(--tw-bg-opacity));
  box-shadow: 10px 0 0 transparent;
  z-index: 2;
  display: flex;
  border-left: 10px solid rgb(27 28 34 / var(--tw-bg-opacity));
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 5px;
  padding-top: 40px;
}

.navigation ul li {
  position: relative;
  list-style: none;
  width: 100%;
}

.navigation ul li .active {
  background: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

:global(.dark) .navigation ul li .active {
  background: #24252d;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.navigation ul li .active a::before {
  content: "";
  position: absolute;
  top: -30px;
  right: 0;
  height: 30px;
  width: 30px;
  background: #e8eaed;
  border-radius: 50%;
  box-shadow: 10px 15px 0 #fff;
}

.navigation ul li .active a::after {
  content: "";
  position: absolute;
  bottom: -30px;
  right: 0;
  height: 30px;
  width: 30px;
  background: #e8eaed;
  border-radius: 50%;
  box-shadow: 10px -15px 0 #fff;
}

:global(.dark) .navigation ul li .active a::before {
  content: "";
  position: absolute;
  top: -30px;
  right: 0;
  height: 30px;
  width: 30px;
  background: #1b1c22;
  border-radius: 50%;
  box-shadow: 10px 15px 0 #24252d;
}

:global(.dark) .navigation ul li .active a::after {
  content: "";
  position: absolute;
  bottom: -30px;
  right: 0;
  height: 30px;
  width: 30px;
  background: #1b1c22;
  border-radius: 50%;
  box-shadow: 10px -15px 0 #24252d;
}

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: black;
}

:global(.dark) .navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #fff;
}

.navigation ul li a .title {
  position: relative;
  display: block;
  line-height: 60px;
  padding-left: 10px;
  height: 60px;
  white-space: nowrap;
}
