@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  @apply dark:bg-[#1b1c22] bg-gray-100 ;
  /* background: #1b1c22; color of the tracking area */
  
  
}

*::-webkit-scrollbar-thumb {
  @apply dark:bg-[#24252d] bg-white border-[3px] border-solid border-gray-100 dark:border-[#1b1c22];

  @apply dark:bg-[#46464d] bg-white border-[3px] border-solid border-gray-100 dark:border-[#1b1c22];

  /* background-color: #24252d; color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */

  /* creates padding around scroll thumb */
  /* border: 3px solid #1b1c22;  */
}

.instruction-selector {
}

.integration-selector {
}

.cloud-integration-selector {
}

.application-nav-selector {
}

.create-application-btn-selector {
}

.new-general-selector {
}

.target-selector {
}

.continue-btn-selector {
}

.new-repository-selector {
}

.new-choose-repository-selector {
}

.new-work-org-selector {
}

.new-repository-details-selector {
}

.new-branch-selector {
}

.repo-continue-btn-selector {
}

.new-cloud-selector {
}

.new-repo-modal-selector {
}

.review-selector {
}

.add-user-selector {
}
.add-user-modal-selector {
}
.more-options-selector {
}
.edit-user-selector {
}

.aws-status-selector {
}

.half-circle {
  width: 33px;

  height: 20px; /* as the half of the width */
  /* rotate: 90deg; */
  border-top-left-radius: 150px; /* 100px of height + 10px of border */
  border-top-right-radius: 150px; /* 100px of height + 10px of border */
  border: 2px solid #4ade80;
  border-bottom: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
::-moz-selection {
  /* Code for Firefox */
  color: black;
  background: #818cf8;
}
::selection {
  background: #818cf8;
  color: black;
}

.fyoutailwind svg {
  display: inline !important;
}

.templateIntegrationLogo svg {
  height: 400px;
  width: 100%;
  object-position: center;
  object-fit: cover;
}

.Loader {
  width: 100%;
  object-position: center;
  object-fit: cover;
  height: 400px;
}

.fyoueverything svg {
  display: inline !important;
  object-position: center;
  object-fit: cover;
  width: 100%;
}
.table-icons svg {
  height: 35px;
  width: 35px;
}

.sm-icons svg {
  height: 15px;
  width: 15px;
}
.md-icons svg {
  height: 100px;
  width: 100px;
}

.template-images svg {
  height: 55px;
  margin-left: auto;
  margin-right: auto;
  width: 48;
}

.app-composition-images svg {
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.repo-language-images svg {
  height: 60px;
  width: 60px;
}

.integration-active-list-images svg {
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 30px;
}

.integration-tailwind svg {
  height: 55px;
  margin-left: auto;
  margin-right: auto;
  width: 48;
}

.tempAWSLogo svg {
  display: inline !important;
  object-position: center;
  object-fit: cover;
  height: 75%;
  width: 75%;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.AppWrapper {
  width: 500px;
  height: 400px;
  position: relative;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.Form {
  animation: fade 200ms ease-out;
}

.FormGroup {
  margin: 0 1px 20px;
  padding: 4px;
  border-style: none;
  background-color: #fff;
  will-change: opacity, transform;
  /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #fff; */
  border-radius: 6px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid rgba(88, 88, 90, 0.06);
}

.FormRow:first-child {
  border-top: none;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fff;

  /* Hack to hide the default webkit autofill */

  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: black;

  animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
  color: gray;
}

.StripeElement--webkit-autofill {
  background: white !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

input,
button {
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
  outline: none;
  border-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.SubmitButton {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: rgb(185 28 28);

  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
}

.SubmitButton:active {
  background-color: rgb(172, 73, 73);
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 red;
  transform: scale(0.99);
}

.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: rgb(201, 115, 115);
  box-shadow: none;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 10px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}

/* .should-highlight-exposure {
  @apply !border-blue-300 !border-4;
} */

/* *:has(.should-highlight-exposure:hover) .highlighted-Exposure {
  @apply scale-110   ;
} */

/* body:has(* > .should-highlight-exposure) .highlighted-Exposure {
  @apply scale-105;
} */

.DisclosureBtn {
  @apply flex w-full justify-between rounded-lg dark:bg-dark-main px-4 py-2 text-left text-lg font-medium dark:text-gray-400 text-gray-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75;
}
